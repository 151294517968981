import React from "react"
import Navigation from "../components/navigation"
import Section from "../components/section"
import Article from "../components/article"
import Footer from "../components/footer"

const Proximamente = () => {
  return (
    <>
      <Navigation />
      <Section color="#fff">
        <Article
          layout="narrow"
          className="full-screen flex align-center justify-center"
        >
          <h1 className="center playfair bolder blue1 font-size-verylarge">
            404: Esta página no existe
          </h1>
        </Article>
      </Section>
      <Footer bottom />
    </>
  )
}

export default Proximamente
